import React from 'react';
import { Switch } from 'antd';

export default class WeeklyReportColumnModel {
  static WeeklyReportColumn = [
    // {
    //   title: 'Weekly Report ID',
    //   align: 'left',
    //   field: 'weeklyReportId',
    //   validate: (rowData: any) => rowData.weeklyReportId === '' ? { isValid: false, helperText: 'Required' } : true
    // },
    {
      title: 'Employee ID',
      align: 'left',
      field: 'employeeId',
      render: (rowData: any) => rowData.employee.firstName,
      validate: (rowData: any) => rowData.employeeId === '' ? { isValid: false, helperText: 'Required' } : true
    },
    {
      title: 'Report Date',
      align: 'left',
      field: 'reportDate',
      render: (rowData: any) => new Date(rowData.reportDate).toLocaleDateString(),
      validate: (rowData: any) => rowData.reportDate === '' ? { isValid: false, helperText: 'Required' } : true
    },
    {
      title: 'Approval Notes',
      align: 'left',
      field: 'content',
      validate: (rowData: any) => rowData.content === '' ? { isValid: false, helperText: 'Required' } : true
    },
    {
      title: 'Approval Status',
      align: 'left',
      field: 'approvalStatus',
      render: (rowData: any) => {
        const statusName = (status: string) => {
          switch (status) {
            case '0':
              return 'Not Approved';
            case '-1':
              return 'Rejected';
            case '-2':
              return 'Draft';
            default:
              return 'Approved';
          }
        };
        const status = statusName(rowData.approvalStatus);
        const color = rowData.approvalStatus === '0' ? 'orange' : rowData.approvalStatus === '-1' ? 'red' : rowData.approvalStatus === '-2' ? 'blue' : 'green';
        return <span style={{ color }}>{status}</span>;
      },
      validate: (rowData: any) => rowData.approvalStatus === '' ? { isValid: false, helperText: 'Required' } : true
    }
  ];
}