import { Button } from "antd"
import React from "react"

export const ReturnManagementColumnModel = (props?: any) => {
  return [
    {
      title: 'Box Bar Code',
      align: 'left',
      field: 'barCode',
      editable: 'never'
    },
    {
      title: 'Product',
      align: 'left',
      field: 'productName',
      editable: 'never'
    },
    {
      title: 'Quantity',
      align: 'left',
      field: 'quantity',
      editable: 'never'
    },
    {
      title: 'Return Quantity',
      align: 'left',
      field: 'returnQuantity',
      render: (rowData: any) => (
        <div>
          <Button onClick={() => {
            const newValue = prompt('Enter new return quantity:', rowData.returnQuantity);
            if (newValue !== null) {
              props.onButtonClick(rowData, 'returnQuantity', newValue);
            }
          }}>Edit</Button>
          <span>{rowData.returnQuantity !== undefined ? rowData.returnQuantity : 0}</span>
        </div>
      )
    }    
  ]
}
