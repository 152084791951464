import React, { useEffect, useState } from 'react';
import WeeklyReportColumnModel from './weekly-report-column-model';
import CommonTablePage from '../../../components/common/common-table/common-table-page';
import { urlKey } from '../../../services/api/api-urls';
import SweetAlertService from '../../../services/lib/utils/sweet-alert-service';
import { ApiRequest } from '../../../services/api/api';
import WeeklyReportDialog from './weekly-report-dialog';
import moment from 'moment'; // 添加这一行
import Swal from 'sweetalert2';
import { getUserId } from '../../../services/lib/utils/auth.utils';
import { useParams } from 'react-router-dom';

const WeeklyReportPage = (): any => {
  const { role } = useParams<{ role?: string }>(); // 获取URL参数
  const [triggerResetData, setTriggerResetData] = useState<any>(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedReport, setSelectedReport] = useState<any>(null); // 新增状态
  const [reportDate, setReportDate] = useState<any>(moment().startOf('isoWeek')); // 新增状态
  const [reports, setReports] = useState<any[]>([]); // 新增状态
  

  const apiUrl = role === 'manager' 
    ? `WeeklyReport/GetAll` 
    : role === 'viewer' 
      ? `WeeklyReport/GetAllAll` 
      : `WeeklyReport/GetByEmployeeId?employeeId=${getUserId()}`;
  useEffect(() => {
    if (role) {
      let roleLower = role.toLowerCase();
      document.title = roleLower === 'manager' ? "Manager Weekly Report Management" : roleLower === 'viewer' ? "Viewer Weekly Report Management" : "Employee Weekly Report Management";
      fetchReports();
    }
  }, [triggerResetData, role]); // 添加 triggerResetData 和 role 作为依赖项以实现数据刷新

  const fetchReports = async () => {
    const response = await ApiRequest({
      url: apiUrl,
      method: 'get'
    });
    console.log('Fetching reports...');
    console.log('Response data:', response.data.data);
    setReports(response.data.data);
  };

const actionButtons: any = [
  {
    icon: 'ghost', //Button attr of Ant design (danger, ghost)
    tooltip:  role?.toLowerCase() === 'manager' ? 'Approve Report' :  role?.toLowerCase() === 'viewer' ? 'View Report' : 'Edit Report', // 根据角色显示不同的文本
    isFreeAction: false,
    onClick: async (event: any, rowData: any) => {
      if (role?.toLowerCase() !== 'manager' && role?.toLowerCase() !== 'viewer' && rowData.approvalStatus === "1") {
        SweetAlertService.errorMessage('This report cannot be edited as it has been approved.', 'Cannot edit approved report.');
        return
      } 
      if ((role?.toLowerCase() == 'manager' || role?.toLowerCase() == 'viewer') && rowData.approvalStatus === "-2") {
        SweetAlertService.errorMessage('This report cannot be open as it is draft.', 'Cannot open draft report.');
        return
      }       
      setSelectedReport(rowData); // 设置选中的报告
      setReportDate(moment(rowData.reportDate)); // 设置当前周的日期,请修改日期格式
      setDialogVisible(true);
      setTriggerResetData(!triggerResetData);
      
    }
  }
];

if ( role?.toLowerCase() !== 'manager' && role?.toLowerCase() !== 'viewer') {
  actionButtons.push(
    {
      icon: 'plus',
      tooltip: 'Add Current Week Report',
      isFreeAction: true,
      onClick: () => {
        const currentWeekStart = moment().startOf('isoWeek').isoWeekday(1);
        console.log('Current Week Start:', currentWeekStart); // 添加console log
        for (const report of reports) {
          if (moment(report.reportDate).isSame(currentWeekStart, 'week')) {
            SweetAlertService.errorMessage('There is already a weekly report for this week. You can edit it.', 'Cannot add a new report.');
            return;
          }
        }
        setSelectedReport(null); // 添加新报告时清空选中的报告       
        setReportDate(currentWeekStart); // 设置当前周的周一
        setDialogVisible(true);
        setTriggerResetData(!triggerResetData);
      }
    },
    {
      icon: 'calendar',
      tooltip: 'Add Specified Date Report',
      isFreeAction: true,
      onClick: () => {
        setSelectedReport(null); // 添加新报告时清空选中的报告        
        showDatePickerSwal(); // 直接调用日期选择器
      }
    }
  );
}

const showDatePickerSwal = () => {
  Swal.fire({
    title: 'Select Date',
    html: '<input type="date" id="swal-input-date" class="swal2-input" value="' + moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD') + '">',
    preConfirm: () => {
      const selectedDate = (document.getElementById('swal-input-date') as HTMLInputElement).value;
      if (selectedDate) {
        const selectedWeekStart = moment(selectedDate).startOf('isoWeek').isoWeekday(1);
        for (const report of reports) {
          if (moment(report.reportDate).isSame(selectedWeekStart, 'week')) {
            SweetAlertService.errorMessage('There is already a weekly report for this week. You can edit it.', 'Cannot add a new report.');
            return;
          }
        }
        const weekStart = moment(selectedWeekStart).startOf('isoWeek');
        console.log('Original Selected Date:', moment(selectedWeekStart).format('YYYY-MM-DD'));
        console.log('Week Start:', weekStart.format('YYYY-MM-DD'));
        setReportDate(weekStart);
        setDialogVisible(true); // 打开对话框
      }
    }
  });
};

  return (
    <div>
      <CommonTablePage
        // urlInfoKey={urlKey.WeeklyReport}
        getAllUrl={apiUrl}
        title={role === 'manager' ? 'Manager Weekly Report Management' : role === 'viewer' ? 'Viewer Weekly Report Management' : 'Employee Weekly Report Management'}
        actionButtons={actionButtons}
        column={WeeklyReportColumnModel.WeeklyReportColumn}
        mappingUpdateData={(dataDetail: any) => {
          return { ...dataDetail, active: dataDetail.active ? 1 : 0 };
        }}
        isNotDeletable={true}
        isNotEditable={true}
        isNotAddable={true}
        triggerResetData={triggerResetData}
      />
      <WeeklyReportDialog 
        visible={dialogVisible}
        onCancel={() => {
          setDialogVisible(false);
          setTriggerResetData(!triggerResetData);
        }}
        onSubmit={() => {
          setDialogVisible(false);
          setTriggerResetData(!triggerResetData);
        }}
        reportDate={reportDate}
        selectedReport={selectedReport} // 传递选中的报告        
        role={role?.toLowerCase()} // 传入role参数
      />
    </div>
  );
};

export default WeeklyReportPage;